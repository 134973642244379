html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}